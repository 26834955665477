import React, { Component } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Row, Col, Button, Spinner, InputGroup, Alert } from 'react-bootstrap';
import { validateForm, validateApigeeResponse, removeValidation } from './Validation';
import './App.css';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiResponse: "",
            email: "",
            phone1: ""
        };
    }


    handleChange = (event) => {
        if (event.target.id === "MP") {
            
            // Mobile phone number. Only allow digits and must start with 7. Either '44' or '0' is added to the api calls in the backend.
            event.target.value = event.target.value.replace(/\D|^[^7]/g, '');
            this.setState({phone1: event.target.value});

        } else if (event.target.id === "EM") {

            // Email address. The user can add what they like, it gets validated in the 'validateForm' function on submit.
            this.setState({email: event.target.value});

        } else {

            console.log("Error: handleChange()");

        }
    }


    submitForm = (event) => {
        event.preventDefault();

        document.querySelector("#submitButton").disabled = true;
        document.querySelector("#optOutText").style.display = "none";
        document.querySelector("#optOutSpinner").style.display = "inline-block";

        // Validate form
        let validationResponse = validateForm(this.state);
        if (Object.values(validationResponse).every(Boolean)) {

            // The form is valid. Send to the API
            console.log("Form is valid");

            console.log(this.state);
            // create axios request to apigee with headers
            axios.post('https://money3-prod.apigee.net/optout', this.state, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'allow-origin': '*'
                }
            })
            .then(res => {
                this.setState({apiResponse: res.data});
            })
            .then(this.handleAPIResponse)
            .catch(err => console.log(err));

            
            
        } else {
            // The form is not valid. Show the errors and re-enable the submit button.
            document.querySelector("#submitButton").disabled = false;
            document.querySelector("#optOutText").style.display = "inline-block";
            document.querySelector("#optOutSpinner").style.display = "none";
            document.querySelector("#optOutTextAlert").classList.remove("d-none");
        }

    }


    handleAPIResponse = () => {

        if (validateApigeeResponse(this.state)) {

            document.querySelector("#optOutText").innerHTML = "Successfully opted out";

            // clear the form
            document.querySelector("#EM").value = "";
            document.querySelector("#MP").value = "";
            removeValidation();

            // display optOutSuccessTextAlert for 6 seconds
            document.querySelector("#optOutSuccessTextAlert").classList.remove("d-none");
            setTimeout(() => {
                document.querySelector("#optOutSuccessTextAlert").classList.add("d-none");
            }, 6000);

            // reset the state
            this.setState({
                apiResponse: "",
                email: "",
                phone1: ""
            });
        } else {

            // display optOutErrorTextAlert for 15 seconds
            document.querySelector("#optOutText").innerHTML = "Opt-out failed";
            document.querySelector("#optOutErrorTextAlert").classList.remove("d-none");
            setTimeout(() => {
                document.querySelector("#optOutErrorTextAlert").classList.add("d-none");
            }, 15000);
        }
        document.querySelector("#submitButton").disabled = false;
        document.querySelector("#optOutText").style.display = "inline-block";
        document.querySelector("#optOutSpinner").style.display = "none";
    }


    render () {
        return (
            <Container className='override-container'>
                <Row>
                    <Col>
                        <h1>Opt out of all our services and marketing</h1>
                        <p>Fill in the form below and Galactic Marketing Ltd will add you to our list of people not to contact again. Please note it may take 3-5 working days for our systems to be fully updated.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form autoComplete="off">
                            <Form.Group controlId="EM">
                                <Form.Label className="label">Your email address</Form.Label>
                                <Form.Control
                                type="email"
                                defaultValue=""
                                name="email"
                                placeholder='jon.doe@example.com'
                                required
                                onFocus={removeValidation}
                                onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='mt-3' controlId="MP">
                                <Form.Label className="label">Your mobile telephone number</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">44</InputGroup.Text>
                                    <Form.Control
                                    maxLength="10"
                                    className="digits"
                                    type="text"
                                    pattern="\d*"
                                    defaultValue=""
                                    placeholder='7700900077'
                                    name="phone1"
                                    required
                                    onFocus={removeValidation}
                                    onChange={this.handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Alert className='mt-3 mb-0 d-none' variant="danger" id="optOutTextAlert">Please fill out at least one of the above fields.</Alert>
                            <Alert className='mt-3 mb-0 d-none' variant="success" id="optOutSuccessTextAlert">Thank you. You have successfully opted out.</Alert>
                            <Alert className='mt-3 mb-0 d-none' variant="danger" id="optOutErrorTextAlert">An error occurred opting you out. The error has been logged. Please try again, if the error persists please email us at compliance@galacticmarketing.co.uk. Thank you.</Alert>
                            <Button id="submitButton" className='mt-3' variant="primary" onClick={this.submitForm}>
                                <Spinner as="span" id="optOutSpinner" animation="border" size="sm" role="status" aria-hidden="true" style={{display:'none'}} />
                                <span id='optOutText'>Opt out now</span>
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
    
}

export default App;